import React, {FunctionComponent} from 'react';
import Layout from '../../components/layout';
import Section from '../../components/section';
import {Translate} from '../../components/translations';

const CookiePolicy: FunctionComponent = () => (
  <Layout>
    <Section pageheroPading className="policy-section" lgPd>
      <div className="container mb-5">
        <div className="breadcrumb_content text-center">
          <h1 className="f_size_48 f_600 t_color4 l_height54 text-center">Cookie Policy</h1>
          <p className="f_300 page-hero-subTitle mb-0 pricing-p-hero">Last modified May 30, 2022</p>
        </div>
      </div>
      <div className="col-lg-12">
        <Translate name="COOKIE_POLICY_PAGE_CONTENT" />
      </div>
    </Section>
  </Layout>
);

export default CookiePolicy;
